<template>
  <div v-if="isDataLoading" class="full-height full-width d-flex align-center justify-center">
    <v-progress-circular size="96" width="8" color="primary" indeterminate />
  </div>
  <v-simple-table v-else class="body-2 border-l border-t border-b">
    <thead>
      <tr>
        <th
          v-for="header in headers"
          :key="header.value"
          :style="`width: ${header.width}`"
          class="border-r pa-1"
          style="vertical-align: top"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="machinery in machineryArray" :key="machinery.id" style="height: 128px">
        <td
          v-for="field in headers"
          :key="field.value"
          class="border-r pa-1"
          style="vertical-align: top"
        >
          <template v-if="!field.showEmpty">{{ machinery[field.value] }}</template>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import machineryService from '@/api/machinery-service';

export default {
  name: 'MachineriesPrint',

  mixins: [crudMixin],

  data() {
    return {
      isDataLoading: true,
      machineryArray: [],
    };
  },

  computed: {
    headers() {
      return [
        { value: 'name', text: this.$t('name'), width: 'auto' },
        { value: 'reg_number', text: this.$t('reg_number'), width: 'auto' },
        {
          value: 'inspection_date',
          text: this.$t('inspection_date'),
          showEmpty: true,
          width: '128px',
        },
        { value: 'comment', text: this.$t('comment'), showEmpty: true, width: '50%' },
      ];
    },
  },

  created() {
    const params = {
      ...this.$route.query,
      pageSize: 500,
    };
    this.crudMixin_getPage(machineryService.getPage, 'machinery', 1, params);
  },
};
</script>
